import React from "react"

import { 
     EuiPage,
     EuiTitle,
     EuiPageContent,
     EuiSpacer,
     EuiText,
     Link
    
    } from '@elastic/eui';

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {

    
  return <Layout>
    <SEO 
      title="О сайте"
      canonical="/about/"
    />
    
    <EuiPage restrictWidth={ 1000 }>
      <EuiPageContent>    
      <EuiTitle size="m">
            <h1>О сайте</h1>
          </EuiTitle>
       <EuiSpacer />   
       <EuiText>
       <p>По сути, 3TGBrokers - это подробный список брокеров Forex, основанный на минималистичных и удобных концепциях. Несмотря на то, что большинство брокерских компаний преследуют одну и ту же цель в своей основе, между ними все еще есть много различий. Вот почему мы разделили базовые функции в несколько фильтров и поместили брокеров в отдельные категории. Меню слева (на главной странице) поможет сузить область поиска и выделить компании с необходимыми продуктами и услугами.</p>

       <p>Честно говоря, добавить особо нечего. Если у вас был положительный опыт торговли с каким-либо брокером Forex, представленным на этом сайте, оставьте, пожалуйста, отзыв о нем. Если у вас был плохой опыт - оставьте, пожалуйста, отзыв аналогично. Если вы знаете какого-либо хорошего брокера Forex, и его здесь нет - сообщите нам об этом. Если у вас вообще есть вопросы - смело задавайте. Вы можете отправить нам электронное письмо или заполнить форму запроса на странице «Контакты». Мы приветствуем любые примечания или комментарии, которые могут помочь улучшить наш сайт и его содержание.</p>

       <p>Желаем удачи в торговле!</p>
        </EuiText> 
    </EuiPageContent>
    </EuiPage>
  </Layout>
}






